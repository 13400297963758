<template>
  <v-data-table
    v-model="arCfeCodeList"
    :headers="headers"
    :items="items"
    :loading="loading"
    class="dt-listing-simple"
    disable-pagination
    disable-sort
    hide-default-footer
    show-select
    @item-selected="onToggle"
  >
    <template #[`header.data-table-select`]>
      <span />
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Mixins, VModel, Watch } from "vue-property-decorator";
import type { CfeCodeData } from "@planetadeleste/vue-mc-gw";
import { CfeCodeCollection, Company } from "@planetadeleste/vue-mc-gw";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import DataTableMixin from "@/mixins/DataTableMixin";
import CacheMixin from "@/mixins/CacheMixin";
import { AppModule } from "@/store/app";
import { findIndex, map } from "lodash";
import { ConfigModule } from "@/store/config";

type DataTableSelectRow = {
  item: CfeCodeData;
  value: boolean;
};

@Component
export default class CompanyCfeCodes extends Mixins(
  DataTableMixin,
  CacheMixin
) {
  @VModel({ type: Object, default: () => new Company() })
  obCompany!: Company;
  loading = false;
  headers: DataTableHeader[] = [
    { text: "form", value: "form" },
    { text: "line", value: "line" },
    { text: "description", value: "description" },
  ];
  arCfeCodeList: CfeCodeData[] = [];
  obCollection: CfeCodeCollection = new CfeCodeCollection();

  get cacheKey() {
    return "CfeCode";
  }

  get cacheIdentifier() {
    return "models";
  }

  get company() {
    return AppModule.company;
  }

  get companyId(): number | undefined {
    return this.obCompany ? this.obCompany.id : undefined;
  }

  get items() {
    let arItems = this.cache<CfeCodeData[]>();

    if (!arItems || !arItems.length) {
      arItems = this.obCollection.getModelList() as CfeCodeData[];
    }

    return arItems;
  }

  @Watch("companyId")
  async onChangeCompany() {
    this.loading = true;
    await this.loadCompanyCfeCodes();
    this.loading = false;
  }

  async load() {
    this.loading = true;
    await this.loadCompanyCfeCodes();
    const arItems = this.cache<CfeCodeData[]>();

    if (arItems && arItems.length) {
      this.loading = false;
      return;
    }

    this.obCollection.clear();
    await this.obCollection.list();
    this.cache(this.obCollection.getModelList());

    this.loading = false;
  }

  async loadCompanyCfeCodes() {
    if (!this.obCompany || !this.obCompany.id) {
      return;
    }

    const obResponse = await this.obCompany.getCfeCodes();
    this.arCfeCodeList = obResponse.getData().data;
  }

  async onToggle(obValue: DataTableSelectRow) {
    const bSelected = obValue.value;
    const obItem = obValue.item;
    const arCfeCodeIdList = map(this.arCfeCodeList, "id");

    // Add selected id
    if (bSelected) {
      arCfeCodeIdList.push(obItem.id);
    } else {
      // Remove selected id
      const idx = findIndex(arCfeCodeIdList, (itemId) => itemId === obItem.id);
      if (idx !== -1) {
        arCfeCodeIdList.splice(idx, 1);
      }
    }

    this.loading = true;

    // Store selected invoice types ids
    await this.obCompany.addUpdateCfeCodes(arCfeCodeIdList);

    // Check for current selected company to reload invoice types
    if (this.obCompany.id === this.company.id) {
      await ConfigModule.loadCompanyCfeCodes(true);
    }

    this.loading = false;
  }

  mounted() {
    this.mapDTHeaders();
    this.load();
  }
}
</script>
